import {
	ALCHEMY_API_URL,
	FLOOD_WALLETCONNECT_PROJECT_ID,
	arbitrum,
} from "@/utils/config";
import { getDefaultConfig } from "@flood-protocol/connectkit";
import { Chain } from "viem/chains";
import { http, createConfig } from "wagmi";

export const DEFAULT_CHAIN = arbitrum;

export const config = createConfig(
	getDefaultConfig({
		transports: [http(ALCHEMY_API_URL)],
		walletConnectProjectId: FLOOD_WALLETCONNECT_PROJECT_ID,
		appName: "Flood",
		appDescription: "Flood is the MEV free, Optimal DEX aggregator",
		appUrl: "https://app.flood.bid",
		chains: [arbitrum as Chain],
	}),
);

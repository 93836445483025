import { useAccount } from "@/hooks/account";
import { currencyId, isToken } from "@/utils/currency";
import { useQuery } from "@tanstack/react-query";
import { FloodChain, permit2Address } from "flood-sdk";
import { erc20Abi } from "viem";
import { usePublicClient } from "wagmi";
import { useTokenList } from "./tokenList";

export function allowanceQueryKey(
	address: string | undefined,
	chain: FloodChain | undefined,
	status: string,
) {
	return ["allowance", chain?.id, address, `tokenList-${status}`];
}

type AllowanceMap = Record<string, bigint>;
export function useAllowance() {
	const { address, chain } = useAccount();

	const client = usePublicClient();
	const { tokenList, status } = useTokenList();
	const query = useQuery({
		queryKey: allowanceQueryKey(address, chain, status),
		queryFn: async () => {
			if (!chain || !tokenList || !address || !client) {
				throw new Error("Missing chainId, tokenList, address or client");
			}
			const tokens = tokenList.filter(isToken);
			const allowances = (await client.multicall({
				allowFailure: false,
				contracts: tokens.map((token) => ({
					address: token.address,
					abi: erc20Abi,
					functionName: "allowance",
					args: [address, permit2Address],
				})),
			})) as bigint[];

			return allowances.reduce((acc, allowance, i) => {
				acc[currencyId(tokens[i])] = allowance;
				return acc;
			}, {} as AllowanceMap);
		},
		enabled:
			Boolean(chain) && tokenList && Boolean(address) && status === "success",
		staleTime: Number.POSITIVE_INFINITY,
	});

	return { allowance: query.data, ...query };
}

import { AdvancedImage } from "@cloudinary/react";
import classNames from "classnames";
import { type FC } from "react";

import { type Currency } from "@/utils/types";

import {
	bigCurrencyLogoStyle,
	defaultCurrencyLogoStyle,
} from "./CurrencyLogo.css";

export type CurrencyLogoProps = {
	currency: Currency;
	size?: "default" | "big";
	className?: string;
};

import { Cloudinary } from "@cloudinary/url-gen";
import { quality } from "@cloudinary/url-gen/actions/delivery";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { autoBest } from "@cloudinary/url-gen/qualifiers/quality";

const cld = new Cloudinary({
	cloud: {
		cloudName: "dhicwabwz",
	},
});

export const CurrencyLogo: FC<CurrencyLogoProps> = ({
	currency,
	size = "default",
	className,
}) => {
	return (
		<AdvancedImage
			className={classNames(
				size === "default" ? defaultCurrencyLogoStyle : bigCurrencyLogoStyle,
				className,
			)}
			cldImg={cld
				.image(`${currency.chainId}-${currency.symbol}`)
				.resize(scale().width(30))
				.delivery(quality(autoBest()))}
			alt={`${currency.symbol} logo`}
		/>
	);
};

import classNames from "classnames";
import {
	type ButtonHTMLAttributes,
	type PropsWithChildren,
	forwardRef,
} from "react";

import {
	buttonErrorStyle,
	buttonFillStyle,
	buttonGradientStyle,
	buttonMediumWidthStyle,
	buttonSmallSizeStyle,
	buttonStyle,
	buttonWideWidthStyle,
} from "./Button.css";

export type ButtonProps = PropsWithChildren<{
	variant?: "primary" | "accent" | "error";
	fill?: boolean;
	maxWidth?: "medium" | "wide";
	size?: "small" | "regular";
	externalTo?: string;
}> &
	ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			variant = "primary",
			children,
			fill = true,
			maxWidth = "medium",
			size = "regular",
			externalTo,
			className,
			...buttonProps
		},
		ref,
	) => {
		const classes = classNames(
			{
				[buttonStyle]: variant === "primary",
				[buttonErrorStyle]: variant === "error",
				[buttonGradientStyle]: variant === "accent",
				[buttonMediumWidthStyle]: maxWidth === "medium",
				[buttonWideWidthStyle]: maxWidth === "wide",
				[buttonSmallSizeStyle]: size === "small",
				[buttonFillStyle]: fill,
			},
			className,
		);

		if (externalTo) {
			return (
				<a
					href={externalTo}
					className={classes}
					target="_blank"
					rel="noopener noreferrer"
				>
					{children}
				</a>
			);
		}

		return (
			<button type="button" className={classes} {...buttonProps} ref={ref}>
				{children}
			</button>
		);
	},
);
Button.displayName = "Button";

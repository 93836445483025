import SquigglyLine from "@/assets/squiggly-line.svg?react";
import { DEFAULT_CHAIN } from "@/services/chain";
import {
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { FC, useEffect, useRef } from "react";

import { useAccount } from "@/hooks/account";
import { useAuthStore } from "@/services/chain/auth.store.ts";
import {
	OrderWithMeta,
	userOrdersQueryKey,
} from "@/services/orders/orders.hooks.ts";
import { fetchUserOrders } from "@/services/orders/orders.utils";
import { useTokenList } from "@/services/tokenList.ts";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useVirtualizer } from "@tanstack/react-virtual";
import { columns } from "./OrdersTable.columns";
import {
	noOrdersContainerStyle,
	noOrdersSquigglyLineStyle,
	noOrdersSquigglyLineTextStyle,
	rowStyle,
	tableHeaderStyle,
	tableStyle,
} from "./OrdersTable.css";

const EMPTY_ARRAY: OrderWithMeta[] = [];
export const OrdersTable: FC = () => {
	const { address, chain } = useAccount();
	const authToken = useAuthStore((state) => state.token);
	const { tokenMap, status } = useTokenList();

	const { data, hasNextPage, isFetchingNextPage, fetchNextPage } =
		useInfiniteQuery({
			queryFn: async (ctx) => {
				return fetchUserOrders(address, authToken, tokenMap, chain, {
					beforeCursor: ctx.pageParam.beforeCursor,
					limit: 15,
				});
			},
			enabled:
				Boolean(address) &&
				Boolean(authToken) &&
				status === "success" &&
				Boolean(tokenMap) &&
				Boolean(chain),
			getNextPageParam: (lastPage) => {
				if (!lastPage.prevCursor) {
					return null;
				}
				return {
					beforeCursor: lastPage.prevCursor,
				};
			},
			select: (data) => {
				return data ? data.pages.flatMap((d) => d.orders) : [];
			},
			initialPageParam: { beforeCursor: undefined as string | undefined },
			queryKey: [
				"infinite",
				...userOrdersQueryKey(address, authToken, chain, status),
			],
		});

	const parentRef = useRef<HTMLDivElement>(null);

	const table = useReactTable({
		data: data ?? EMPTY_ARRAY,
		columns,
		getCoreRowModel: getCoreRowModel(),
		meta: {
			chain: chain ?? DEFAULT_CHAIN,
		},
	});

	const { rows } = table.getRowModel();

	const rowVirtualizer = useVirtualizer({
		count: hasNextPage ? rows.length + 1 : rows.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 50,
		overscan: 10,
	});

	const virtualRows = rowVirtualizer.getVirtualItems();

	useEffect(() => {
		const lastItem = virtualRows[virtualRows.length - 1];

		if (!lastItem) {
			return;
		}

		if (!parentRef.current) {
			return;
		}

		if (isFetchingNextPage || !hasNextPage) {
			return;
		}

		const { scrollHeight, scrollTop, clientHeight } = parentRef.current;
		// If we're at the last item, or have less than 300px to scroll, fetch next page
		if (
			lastItem.index >= rows.length - 1 ||
			scrollHeight - scrollTop - clientHeight < 300
		) {
			fetchNextPage();
		}
	}, [
		hasNextPage,
		fetchNextPage,
		rows.length,
		isFetchingNextPage,
		virtualRows,
	]);

	return (
		<div className={tableStyle}>
			<div className={tableHeaderStyle}>
				{table.getFlatHeaders().map((header) => (
					<div key={header.id}>
						{header.isPlaceholder
							? null
							: flexRender(header.column.columnDef.header, header.getContext())}
					</div>
				))}
			</div>
			<div
				ref={parentRef}
				style={{
					height: 500,
					width: 940,
					overflowY: "auto",
				}}
			>
				<div
					style={{
						height: rowVirtualizer.getTotalSize(),
						width: "100%",
						position: "relative",
					}}
				>
					{virtualRows.map((virtualRow) => {
						const isLoaderRow = virtualRow.index > rows.length - 1;
						const row = rows[virtualRow.index];

						return (
							<div
								key={virtualRow.index}
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: virtualRow.size,
									transform: `translateY(${virtualRow.start}px)`,
								}}
							>
								{isLoaderRow ? (
									<div className={noOrdersContainerStyle}>
										<span className={noOrdersSquigglyLineTextStyle}>
											Loading...
										</span>
										<SquigglyLine className={noOrdersSquigglyLineStyle} />
									</div>
								) : (
									<div key={row.original.hash} className={rowStyle}>
										{row.getVisibleCells().map((cell) => (
											<div key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</div>
										))}
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

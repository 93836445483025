import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import classNames from "classnames";
import type React from "react";

import {
	scrollAreaRootStyle,
	scrollAreaWidthGrowStyle,
	scrollViewportStyle,
	scrollbarNoTrackStyle,
	scrollbarThumbStyle,
	scrollbarTrackStyle,
} from "./ScrollArea.css";

interface ScrollAreaProps
	extends React.PropsWithChildren,
		React.HTMLAttributes<HTMLDivElement> {
	growWidth?: boolean;
	noTrack?: boolean;
	className?: string;
}

export const ScrollArea: React.FC<ScrollAreaProps> = ({
	growWidth = false,
	noTrack = false,
	children,
	className,
	...props
}) => {
	return (
		<ScrollAreaPrimitive.Root
			className={classNames(
				scrollAreaRootStyle,
				growWidth && scrollAreaWidthGrowStyle,
				className,
			)}
		>
			<ScrollAreaPrimitive.Viewport className={scrollViewportStyle} {...props}>
				{children}
			</ScrollAreaPrimitive.Viewport>
			<ScrollAreaPrimitive.Scrollbar
				orientation="vertical"
				className={classNames(
					scrollbarTrackStyle,
					noTrack && scrollbarNoTrackStyle,
				)}
			>
				<ScrollAreaPrimitive.Thumb className={scrollbarThumbStyle} />
			</ScrollAreaPrimitive.Scrollbar>
		</ScrollAreaPrimitive.Root>
	);
};

import { darkColors, lightColors } from "@/css";

export const darkModeMediaQuery = window.matchMedia(
	"(prefers-color-scheme: dark)",
);

export function applyDarkModeThemeClasses(isDarkMode: boolean) {
	if (isDarkMode) {
		document.body.classList.add(darkColors);
		document.body.classList.remove(lightColors);
	} else {
		document.body.classList.add(lightColors);
		document.body.classList.remove(darkColors);
	}
}

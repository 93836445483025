import {
	ChainIcon,
	ConnectKitButton,
	useChains,
	useSIWE,
} from "@flood-protocol/connectkit";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import classNames from "classnames";
import { forwardRef } from "react";

import FloodAltLogo from "@/assets/logos/flood-alt.svg?react";
import { Button, type ButtonProps } from "@/components/Button";
import { formatCurrency } from "@/utils/formatting";

import { useAccount } from "@/hooks/account";
import { useBalances } from "@/services/balances";
import { useNativeCurrency } from "@/services/tokenList";
import { currencyId } from "@/utils/currency";
import {
	accountButtonStyle,
	connectButtonContainerStyle,
	singleButtonConnectButtonContainerStyle,
} from "./EthereumButton.css";

export type EthereumButtonProps = {
	connectOnly?: boolean;
	className?: string;
} & ButtonProps;

export const EthereumButton = forwardRef<
	HTMLButtonElement,
	EthereumButtonProps
>(
	(
		{
			className,
			connectOnly = false,
			variant: buttonVariant = "accent",
			...buttonProps
		},
		ref,
	) => {
		const nativeCurrency = useNativeCurrency();
		const { balances } = useBalances();
		const nativeCurrencyBalance = balances?.[currencyId(nativeCurrency)];
		const { isSignedIn } = useSIWE();
		const { address, chain, isConnected} = useAccount();
		const unsupported = useChains().every(c => c.id !== chain?.id)

		return (
			<ConnectKitButton.Custom>
				{({ show, ensName }) => {
					const connected = isConnected && address && chain;
					const singleButton = !connected || unsupported || !isSignedIn;

					if (connectOnly && !singleButton) {
						return null;
					}

					return (
						<div
							className={classNames(
								connectButtonContainerStyle,
								{
									[singleButtonConnectButtonContainerStyle]: singleButton,
								},
								className,
							)}
						>
							{(() => {
								if (!connected) {
									return (
										<Button
											{...buttonProps}
											variant={buttonVariant}
											onClick={show}
											ref={ref}
										>
											Connect wallet
										</Button>
									);
								}

								if (unsupported) {
									return (
										<Button
											variant="error"
											{...buttonProps}
											onClick={show}
											disabled={false}
											ref={ref}
										>
											Change network
										</Button>
									);
								}

								if (!isSignedIn) {
									return (
										<Button
											{...buttonProps}
											variant={buttonVariant}
											onClick={show}
											ref={ref}
										>
											Sign in
										</Button>
									);
								}

								const truncatedAddress = `${address.slice(
									0,
									4,
								)}…${address.slice(-4)}`;

								return (
									<>
										<button onClick={show} type="button">
											<ChainIcon id={chain.id} />
										</button>

										<span>
											 {nativeCurrencyBalance
												? `${formatCurrency([
														nativeCurrencyBalance,
														nativeCurrency.decimals,
												  ])} ${nativeCurrency.symbol}`
												: ""}
										</span>

										<button
											onClick={show}
											type="button"
											className={accountButtonStyle}
											ref={ref}
										>
											<FloodAltLogo />
											<span>{ensName || truncatedAddress}</span>
											<ChevronDownIcon />
										</button>
									</>
								);
							})()}
						</div>
					);
				}}
			</ConnectKitButton.Custom>
		);
	},
);
EthereumButton.displayName = "EthereumButton";

export type ConnectButtonProps = Omit<EthereumButtonProps, "connectOnly">;
export const ConnectButton = forwardRef<HTMLButtonElement, ConnectButtonProps>(
	(props, ref) => {
		return <EthereumButton connectOnly {...props} ref={ref} />;
	},
);
ConnectButton.displayName = "ConnectButton";

import { ConnectKitProvider } from "@flood-protocol/connectkit";
import { useAtom } from "jotai";
import { type PropsWithChildren } from "react";

import { vars } from "@/css";
import { config } from "@/services/chain";
import { isDarkModeAtom } from "@/services/userPreferences/userPreferences.store";
import { WagmiProvider } from "wagmi";
import { AuthProvider } from "./auth.provider";

const connectKitTheme = {
	"--ck-font-family": vars.fontFamily,
};

export function ChainProvider({ children }: PropsWithChildren) {
	const [isDarkMode] = useAtom(isDarkModeAtom);

	return (
		<WagmiProvider config={config}>
			<AuthProvider>
				<ConnectKitProvider
					mode={isDarkMode ? "dark" : "light"}
					customTheme={connectKitTheme}
				>
					{children}
				</ConnectKitProvider>
			</AuthProvider>
		</WagmiProvider>
	);
}

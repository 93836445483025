import { FloodChain } from "flood-sdk";
import { useAccount as wagmi__useAccount } from "wagmi";

type FloodUseAccountReturnType = Omit<
	ReturnType<typeof wagmi__useAccount>,
	"chain"
> & { chain: FloodChain | undefined };
export function useAccount(): FloodUseAccountReturnType {
	const account = wagmi__useAccount();
	return account as FloodUseAccountReturnType;
}

import { arbitrum as floodArbitrum } from "flood-sdk";
import type { FloodChain } from "flood-sdk/types";

export const ALCHEMY_API_URL: string =
	"https://arb-mainnet.g.alchemy.com/v2/GnfizKSCnSL0iJgWuylNTmYeQeJ0RmtP";

export const arbitrum: FloodChain =
	(import.meta.env.VITE_VERCEL_ENV ?? "development") === "production"
		? floodArbitrum
		: {
				...floodArbitrum,
				floodUrl: "https://staging.arbitrum.flood.bid",
		  };

export const FLOOD_WALLETCONNECT_PROJECT_ID =
	"d478b9c523781b4bcb5d65eaacd3acf3";

export const DISCORD_URL = "https://discord.gg/pWdabpGKUJ";

export const SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN;
export const POSTHOG_TOKEN: string = import.meta.env.VITE_POSTHOG_TOKEN;
export const USERBACK_TOKEN: string = import.meta.env.VITE_USERBACK_TOKEN;
export const IS_DEV = import.meta.env.DEV;
// only enable analytics in production
export const ANALYTICS_ENABLED = !IS_DEV;

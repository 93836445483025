import { createContext, useContext } from "react";

export const SwapContext = createContext<SwapStore | null>(null);

import { useRef } from "react";
import { useStore } from "zustand";
import {
	SwapStore,
	SwapStoreActions,
	SwapStoreProps,
	SwapStoreState,
	createSwapStore,
} from "./swap.store";

type SwapProviderProps = React.PropsWithChildren<SwapStoreProps>;

export function SwapProvider({ children, ...props }: SwapProviderProps) {
	const storeRef = useRef<SwapStore>();
	if (!storeRef.current) {
		storeRef.current = createSwapStore(props);
	}
	return (
		<SwapContext.Provider value={storeRef.current}>
			{children}
		</SwapContext.Provider>
	);
}

export function useSwapStore<T>(
	selector: (state: SwapStoreState & SwapStoreActions) => T,
): T {
	const store = useContext(SwapContext);
	if (!store) throw new Error("Missing SwapContext.Provider in the tree");
	return useStore(store, selector);
}

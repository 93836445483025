import { Provider as TooltipProvider } from "@radix-ui/react-tooltip";
import { type FC, type PropsWithChildren } from "react";

import DiscordLogo from "@/assets/logos/discord.svg?react";
import TwitterLogo from "@/assets/logos/twitter.svg?react";
import { Header } from "@/components/Header";
import { ScrollArea } from "@/components/ScrollArea";
import { DISCORD_URL } from "@/utils/config";

import {
	footerContainerStyle,
	footerPolicyLinkStyle,
	footerSocialLinkStyle,
	footerStyle,
	layoutContainerStyle,
	layoutScrollAreaStyle,
	mainContentStyle,
} from "./Layout.css";

export const Layout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<TooltipProvider delayDuration={300}>
			<ScrollArea className={layoutScrollAreaStyle} noTrack>
				<div className={layoutContainerStyle}>
					<Header />
					<main className={mainContentStyle}>{children}</main>
					<footer className={footerStyle}>
						<div className={footerContainerStyle}>
							<a
								href="https://www.flood.bid/terms-of-use"
								target="_blank"
								rel="noopener noreferrer"
								className={footerPolicyLinkStyle}
							>
								Terms of Use
							</a>
							<a
								href="https://www.flood.bid/privacy-policy"
								target="_blank"
								rel="noopener noreferrer"
								className={footerPolicyLinkStyle}
							>
								Privacy Policy
							</a>
							<a
								href="https://www.flood.bid/cookie-policy"
								target="_blank"
								rel="noopener noreferrer"
								className={footerPolicyLinkStyle}
							>
								Cookie Policy
							</a>
						</div>
						<div className={footerContainerStyle}>
							<a
								href="https://twitter.com/bidflood"
								target="_blank"
								rel="noopener noreferrer"
							>
								<TwitterLogo className={footerSocialLinkStyle} />
							</a>
							<a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
								<DiscordLogo className={footerSocialLinkStyle} />
							</a>
						</div>
					</footer>
				</div>
			</ScrollArea>
		</TooltipProvider>
	);
};

import React from "react";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import App from "./App.tsx";
import "./css/global.css";
import "./polyfills.ts";

// biome-ignore lint/style/noNonNullAssertion: just stfu
ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

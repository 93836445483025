import * as Sentry from "@sentry/react";
import { UserbackProvider } from "@userback/react";
import posthog from "posthog-js";
import { type FC, type PropsWithChildren, useEffect } from "react";
import { useAccount } from "wagmi";

import {
	ANALYTICS_ENABLED,
	POSTHOG_TOKEN,
	SENTRY_DSN,
	USERBACK_TOKEN,
} from "@/utils/config";

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
	const { address } = useAccount();

	// init analytics
	useEffect(() => {
		if (!ANALYTICS_ENABLED) return;

		if (SENTRY_DSN) {
			Sentry.init({
				dsn: SENTRY_DSN,
				integrations: [
					Sentry.replayIntegration(),
					Sentry.browserTracingIntegration(),
				],
				// Session Replay
				replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
				replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			});
		}

		if (POSTHOG_TOKEN) {
			posthog.init(POSTHOG_TOKEN, {
				api_host: "https://app.posthog.com",
			});
		}
	}, []);

	// identify Posthog user
	useEffect(() => {
		if (!ANALYTICS_ENABLED || !POSTHOG_TOKEN || !address) return;

		posthog.identify(address);
	}, [address]);

	if (!ANALYTICS_ENABLED || !USERBACK_TOKEN) return <>{children}</>;

	return <UserbackProvider token={USERBACK_TOKEN}>{children}</UserbackProvider>;
};

import { OrderWithMeta } from "@/services/orders/orders.hooks.ts";
import type { CurrencyMap } from "@/services/tokenList.ts";
import { arbitrum } from "@/utils/config.ts";
import type { Native } from "@/utils/types.ts";
import {
	type FloodChain,
	OrderStatus,
	type OrderWithStatus,
	PaginationParams,
	getOrders,
} from "flood-sdk";
import { Address } from "viem";

type Nullable<T> = T | undefined | null;

export async function fetchUserOrders(
	address: Nullable<Address>,
	authToken: Nullable<string>,
	tokenMap: Nullable<CurrencyMap>,
	chain: Nullable<FloodChain>,
	pagination: PaginationParams | undefined,
) {
	if (!address || !authToken || !tokenMap || !chain) {
		return {
			orders: [],
		};
	}
	const response = await getOrders(arbitrum, authToken, {
		offerer: address,
		pagination,
	});

	return {
		...response,
		orders: response.orders.map((order) =>
			intoOriginalOrder(chain, order, tokenMap),
		),
	};
}

export function intoOriginalOrder(
	chain: FloodChain,
	order: OrderWithStatus,
	currenciesMap: CurrencyMap,
): OrderWithMeta {
	const nativeCurrency = currenciesMap[
		`${chain.id}-${chain.nativeCurrency.symbol}`
	] as Native;
	const inputCurrencies = order.offer.map(({ token, amount }) => {
		const currency = currenciesMap[`${chain.id}-${token}`];
		return { currency, amount };
	});
	const usesUnwrapHook =
		order.postHooks.length === 1 &&
		order.postHooks[0].target === order.recipient &&
		order.postHooks[0].data === order.offerer &&
		order.consideration.token === nativeCurrency.wrapped.address;
	const outputCurrency = {
		currency: usesUnwrapHook
			? nativeCurrency
			: currenciesMap[`${chain.id}-${order.consideration.token}`],
		amount:
			order.status === OrderStatus.FULFILLED
				? order.amountOut
				: order.consideration.amount,
	};

	return Object.assign(order, { intent: { inputCurrencies, outputCurrency } });
}

import { useAtom } from "jotai";
import { type FC, useEffect } from "react";

import {
	isDarkModeAtom,
	isDarkModeManualOverrideAtom,
} from "@/services/userPreferences/userPreferences.store";
import {
	applyDarkModeThemeClasses,
	darkModeMediaQuery,
} from "./userPreferences.utils";

let initialColorThemeApplied = false;

export const UserPreferencesManager: FC = () => {
	const [isDarkMode, setIsDarkMode] = useAtom(isDarkModeAtom);
	const [isDarkModeManualOverride] = useAtom(isDarkModeManualOverrideAtom);

	if (!initialColorThemeApplied) {
		// doing this in useEffect would be suboptimal because class would be applied only once first render is complete
		applyDarkModeThemeClasses(
			isDarkModeManualOverride ? isDarkMode : darkModeMediaQuery.matches,
		);
		initialColorThemeApplied = true;
	}

	useEffect(() => {
		if (isDarkModeManualOverride) {
			return;
		}

		const handleChange = () => {
			setIsDarkMode(darkModeMediaQuery.matches);
			applyDarkModeThemeClasses(darkModeMediaQuery.matches);
		};

		darkModeMediaQuery.addEventListener("change", handleChange);
		return () => darkModeMediaQuery.removeEventListener("change", handleChange);
	}, [isDarkModeManualOverride, setIsDarkMode]);

	return null;
};

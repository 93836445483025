import { useAtom } from "jotai";

import {
	isDarkModeAtom,
	isDarkModeManualOverrideAtom,
	isMutedAtom,
} from "@/services/userPreferences/userPreferences.store";
import { applyDarkModeThemeClasses } from "@/services/userPreferences/userPreferences.utils";

export const useDarkMode = () => {
	const [isDarkMode, setIsDarkMode] = useAtom(isDarkModeAtom);
	const [isDarkModeManualOverride, setIsDarkModeManualOverride] = useAtom(
		isDarkModeManualOverrideAtom,
	);

	const toggleDarkMode = () => {
		setIsDarkMode(!isDarkMode);
		applyDarkModeThemeClasses(!isDarkMode);
		setIsDarkModeManualOverride(true); // Set manual override to true
	};

	return { isDarkMode, toggleDarkMode, isDarkModeManualOverride };
};

export const useMute = () => {
	const [isMuted, setIsMuted] = useAtom(isMutedAtom);

	const toggleMute = () => setIsMuted(!isMuted);

	return { isMuted, toggleMute };
};

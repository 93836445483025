import { type RowData, createColumnHelper } from "@tanstack/react-table";
import classNames from "classnames";
import { format } from "date-fns";
import { type FC } from "react";

import OrderCancelledIcon from "@/assets/icon-order-cancelled.svg?react";
import OrderPendingIcon from "@/assets/icon-order-pending.svg?react";
import OrderSuccessIcon from "@/assets/icon-order-success.svg?react";
import { CurrencyLogo } from "@/components/CurrencyLogo";
import {
	amountCellStyle,
	cellWithIconStyle,
	linkStyle,
	mutedCellStyle,
} from "@/components/OrdersTable/OrdersTable.css";
import { OrderWithMeta } from "@/services/orders/orders.hooks";
import { formatCurrency } from "@/utils/formatting";
import { Currency } from "@/utils/types";
import { OrderStatus } from "flood-sdk";
import type { FloodChain, OrderWithStatus } from "flood-sdk";

declare module "@tanstack/table-core" {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface TableMeta<TData extends RowData> {
		chain: FloodChain;
	}
}

const columnHelper = createColumnHelper<OrderWithMeta>();

type AmountCellProps = {
	amount: bigint;
	currency: Currency;
	muted?: boolean;
};
const AmountCell: FC<AmountCellProps> = ({
	amount,
	currency,
	muted = false,
}) => (
	<span className={cellWithIconStyle}>
		<CurrencyLogo currency={currency} />
		<span className={classNames(amountCellStyle, muted && mutedCellStyle)}>
			{formatCurrency([amount, currency.decimals], true)}
		</span>
		<span className={classNames(muted && mutedCellStyle)}>
			{currency?.symbol}
		</span>
	</span>
);

export const columns = [
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore https://github.com/TanStack/table/issues/4224
	columnHelper.accessor("createdAt", {
		header: "Date",
		cell: (props) => <span>{format(new Date(props.getValue()), "PPp")}</span>,
	}),

	columnHelper.accessor("status", {
		header: "Status",
		cell: (props) => {
			const status = props.getValue();
			const icon = getOrderStatusIcon(status);

			return (
				<span className={cellWithIconStyle}>
					{icon}
					{status}
				</span>
			);
		},
	}),

	columnHelper.accessor((row) => row.offer[0].amount, {
		id: "amountIn",
		header: "Swapped",
		cell: (props) => {
			const currency = props.row.original.intent.inputCurrencies[0].currency;
			return <AmountCell amount={props.getValue()} currency={currency} />;
		},
	}),

	columnHelper.display({
		id: "->",
		cell: () => <div style={{ textAlign: "center" }}>→</div>,
	}),

	columnHelper.accessor((order) => order.intent.outputCurrency.amount, {
		id: "amountOut",
		header: "For",
		cell: (props) => {
			const order = props.row.original;
			const amountOut = props.getValue();
			return (
				<AmountCell
					amount={amountOut}
					currency={order.intent.outputCurrency.currency}
					muted={order.status !== OrderStatus.FULFILLED}
				/>
			);
		},
	}),

	columnHelper.display({
		header: "Tx link",
		cell: (props) => {
			const txHash = getOrderTxHash(props.row.original);
			const txUrl = `${props.table.options.meta?.chain?.blockExplorers?.default.url}/tx/${txHash}`;

			return txHash ? (
				<a
					className={linkStyle}
					href={txUrl}
					rel="noopener noreferrer"
					target="_blank"
				>
					{`${txHash.slice(0, 6)}. . .${txHash.slice(-6)}`}
				</a>
			) : (
				<span className={linkStyle}>—</span>
			);
		},
	}),
];

function getOrderStatusIcon(status: OrderStatus) {
	switch (status) {
		case OrderStatus.NEW:
			return <OrderPendingIcon />;
		case OrderStatus.FULFILLED:
			return <OrderSuccessIcon />;
		case OrderStatus.CANCELLED:
			return <OrderCancelledIcon />;
		default:
			return null;
	}
}

function getOrderTxHash(order: OrderWithStatus) {
	switch (order.status) {
		case OrderStatus.FULFILLED:
			return order.transactionHash;
		default:
			return;
	}
}

import { useAccount } from "@/hooks/account";
import { currencyId, isToken } from "@/utils/currency";
import { useQuery } from "@tanstack/react-query";
import type { FloodChain } from "flood-sdk";
import { erc20Abi, zeroAddress } from "viem";
import { usePublicClient } from "wagmi";
import { useNativeCurrency, useTokenList } from "./tokenList";

export function balancesQueryKey(
	address: string | undefined,
	chain: FloodChain | undefined,
	status: string,
) {
	return ["balances", address, chain?.id, `tokenList-${status}`];
}
type BalancesMap = Record<string, bigint>;
// Fetches all balances for the connected address
export function useBalances() {
	const { address, chain } = useAccount();
	const { tokenList, status } = useTokenList();

	const publicClient = usePublicClient();
	const nativeCurrency = useNativeCurrency();
	const balances = useQuery({
		queryKey: balancesQueryKey(address, chain, status),
		queryFn: async () => {
			const balancesMap: BalancesMap = {};
			if (!address || !tokenList || !publicClient) {
				throw new Error("No address or token list");
			}
			const tokens = tokenList.filter(isToken);
			const balances = (await publicClient.multicall({
				allowFailure: false,
				contracts: tokens.map((token) => {
					return {
						address: token?.address ?? zeroAddress,
						abi: erc20Abi,
						functionName: "balanceOf",
						args: [address],
					};
				}),
			})) as bigint[];

			for (let i = 0; i < balances.length; i++) {
				balancesMap[currencyId(tokens[i])] = balances[i];
			}

			// Add the native currency
			balancesMap[currencyId(nativeCurrency)] = await publicClient.getBalance({
				address,
			});

			return balancesMap;
		},
		enabled: !!address && status === "success" && Boolean(tokenList),
	});

	return { balances: balances.data, ...balances };
}

import { arbitrum } from "@/utils/config";
import {
	type SIWEConfig,
	SIWEProvider,
	type SIWESession,
} from "@flood-protocol/connectkit";
import { useQueryClient } from "@tanstack/react-query";
import {
	getAuthInfo,
	getAuthMessage,
	getAuthNonce,
	getAuthToken,
} from "flood-sdk";
import { type PropsWithChildren } from "react";
import { useAccount } from "wagmi";
import { useAuthStore } from "./auth.store";

export function AuthProvider({ children }: PropsWithChildren) {
	const { address } = useAccount();
	const authToken = useAuthStore((state) => state.token);
	const queryClient = useQueryClient();
	const { setToken, clearToken } = useAuthStore((state) => {
		return {
			setToken: state.setToken,
			clearToken: state.clearToken,
		};
	});

	const siweConfig: SIWEConfig = {
		getNonce: (): Promise<string> => getAuthNonce(arbitrum),

		createMessage: (): Promise<string> => {
			if (!address) return Promise.reject("No address");
			return getAuthMessage(arbitrum, {
				scope: {
					type: "user",
				},
				signerAddress: address,
			});
		},
		verifyMessage: async (args: {
			message: string;
			signature: string;
		}): Promise<boolean> => {
			const token = await getAuthToken(arbitrum, {
				message: args.message,
				signature: args.signature as `0x${string}`,
			});
			setToken(token);
			return true;
		},

		getSession: async (): Promise<SIWESession | null> => {
			if (!authToken) {
				return null;
			}

			try {
				const info = await getAuthInfo(arbitrum, authToken);
				return {
					address: info.address,
					chainId: arbitrum.id,
				};
			} catch {
				clearToken();
				return null;
			}
		},

		signOut: async (): Promise<boolean> => {
			clearToken();
			return true;
		},
	};
	// Prefetch the nonce so that it's ready when the user clicks the connect button
	queryClient.prefetchQuery({
		queryKey: ["ckSiweNonce"],
		queryFn: () => getAuthNonce(arbitrum),
	});

	return <SIWEProvider {...siweConfig}>{children}</SIWEProvider>;
}

import { create } from "zustand";
import { persist } from "zustand/middleware";

type AuthStoreState = {
	token: string | null;
};

type AuthStoreActions = {
	setToken: (token: string) => void;
	clearToken: () => void;
};

export const useAuthStore = create<AuthStoreState & AuthStoreActions>()(
	persist(
		(set) => ({
			token: null,
			setToken: (token) => set({ token }),
			clearToken: () => set({ token: null }),
		}),
		{
			name: "flood-storage",
		},
	),
);

import type { Currency, Token } from "./types";

export function currencyId(currency: Currency): string {
	if (isToken(currency)) {
		return `${currency.chainId}-${currency.address}`;
	}
	return `${currency.chainId}-${currency.symbol}`;
}

export function isToken(currency: Currency): currency is Token {
	return (currency as Token).address !== undefined;
}

/* eslint-disable react/prop-types */
import { type FallbackRender } from "@sentry/react";
import classNames from "classnames";

import { centerAlignStyle, h500Style } from "@/css";
import { DISCORD_URL } from "@/utils/config";

import {
	errorFallbackContainerStyle,
	errorFallbackErrorStyle,
} from "./ErrorFallback.css";

import { Button } from "../Button";

let hasClickedTryAgain = false;

export const ErrorFallback: FallbackRender = ({
	error,
	componentStack,
	resetError,
}) => {
	return (
		<div className={errorFallbackContainerStyle}>
			<h1 className={classNames(h500Style, centerAlignStyle)}>
				Something went wrong...
			</h1>
			<p>
				This is embarrassing, but the app died 😵‍💫. Use the button to try again
				and contact us on Discord if the issue persists (best to copy the
				details below).
			</p>
			<pre className={errorFallbackErrorStyle}>
				{error.message}
				{componentStack}
			</pre>
			<Button
				onClick={() => {
					if (hasClickedTryAgain) {
						window.location.reload();
					} else {
						hasClickedTryAgain = true;
						resetError();
					}
				}}
			>
				Try again
			</Button>
			<p>or</p>
			<a href={DISCORD_URL} target="_blank" rel="noreferrer">
				Contact us on Discord
			</a>
		</div>
	);
};

import { type FC } from "react";

import { useAllowance } from "./allowance";
import { useBalances } from "./balances";
import { useSubscribeToOrderEvents } from "./orders/orders.hooks";
import { useTokenList } from "./tokenList";
import { useUsdPrices } from "./usdPrices";

export const SubscriptionsManager: FC = () => {
	useTokenList();
	useBalances();
	useAllowance();
	useUsdPrices();
	useSubscribeToOrderEvents();
	return null;
};

import { useAccount } from "@/hooks/account";
import { currencyId, isToken } from "@/utils/currency";
import type { Currency } from "@/utils/types";
import { useQuery } from "@tanstack/react-query";
import * as dn from "dnum";
import { FloodChain } from "flood-sdk/types";
import type { Address } from "viem";
import { DEFAULT_CHAIN } from "./chain";
import { useTokenList } from "./tokenList";

export type PricesMap = Record<string, number>;
export async function loadUsdPrices(
	chain: FloodChain,
	tokens: Currency[],
): Promise<PricesMap> {
	const response = await fetch(`${chain.floodUrl}/prices`, {
		headers: {
			"Content-Type": "application/json",
		},
	});
	if (!response.ok) {
		throw new Error("Failed to load prices");
	}
	const { prices: apiPrices } = (await response.json()) as {
		prices: Record<Address, number>;
	};

	return tokens.reduce((acc: PricesMap, token: Currency) => {
		const id = currencyId(token);
		if (isToken(token)) {
			acc[id] = apiPrices[token.address];
		} else {
			acc[id] = apiPrices[token.wrapped.address];
		}
		return acc;
	}, {});
}

export function useUsdPrices() {
	const { tokenList } = useTokenList();
	const { chain: maybeChain } = useAccount();
	const chain = maybeChain ?? DEFAULT_CHAIN;
	const query = useQuery({
		queryKey: ["prices", chain.id],
		// biome-ignore lint/style/noNonNullAssertion: Checked in enabled
		queryFn: () => loadUsdPrices(chain, tokenList!),
		staleTime: 20 * 1000, // 20 seconds
		enabled: Boolean(tokenList),
	});

	return { prices: query.data, ...query };
}

export function useUsdValue(
	amount: bigint | undefined,
	currency: Currency | undefined,
): dn.Dnum | undefined {
	const { prices } = useUsdPrices();
	if (!amount || !currency || !prices) {
		return undefined;
	}

	const price = prices[currencyId(currency)];
	const totalUsd = dn.multiply([amount, currency.decimals], price);

	return totalUsd;
}

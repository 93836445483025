import { Toaster } from "@/components/Toaster";
import { UserPreferencesManager } from "@/services/userPreferences/userPreferences.manager";
import { ErrorBoundary } from "@sentry/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AnalyticsProvider } from "./AnalyticsProvider";
import { ArchiveBanner } from "./components/ArchiveBanner";
import { ErrorFallback } from "./components/ErrorFallback";
import { Layout } from "./components/Layout";
import { HistoryPage } from "./pages/HistoryPage";
// import { TradePage } from "./pages/TradePage";
import { ChainProvider } from "./services/chain";
import { QueryProvider } from "./services/query";
import { SubscriptionsManager } from "./services/subscriptions";

function App() {
	return (
		<ErrorBoundary fallback={ErrorFallback}>
			<QueryProvider>
				<ChainProvider>
					<AnalyticsProvider />
					<UserPreferencesManager />
					<SubscriptionsManager />
					<BrowserRouter>
						<ArchiveBanner />
						<Layout>
							<Routes>
								<Route path="/" element={<HistoryPage />} />
								<Route path="/history" element={<HistoryPage />} />
								<Route path="*" element={<HistoryPage />} />
							</Routes>
							<Toaster />
						</Layout>
					</BrowserRouter>
				</ChainProvider>
			</QueryProvider>
		</ErrorBoundary>
	);
}

export default App;

import { useSIWE } from "@flood-protocol/connectkit";
import { type FC } from "react";
import { useAccount } from "wagmi";

import SquigglyLine from "@/assets/squiggly-line.svg?react";
import { EthereumButton } from "@/components/EthereumButton";
import { OrdersTable } from "@/components/OrdersTable";

import { useUserOrders } from "@/services/orders/orders.hooks";
import {
	contentWrapperStyle,
	headerStyle,
	squigglyLineStyle,
	squigglyLineTextStyle,
	wrapperStyle,
} from "./HistoryPage.css";

export const HistoryPage: FC = () => {
	const { isConnected } = useAccount();
	const { isSignedIn } = useSIWE();
	const { orders, status, isFetching, error } = useUserOrders();

	const renderContent = () => {
		if (!isConnected || !isSignedIn) {
			return (
				<>
					<span className={squigglyLineTextStyle}>
						Connect wallet to see your orders
					</span>
					<SquigglyLine className={squigglyLineStyle} />
					<EthereumButton fill={false} />
				</>
			);
		}

		if (isFetching && !orders) {
			return <div>Loading Orders...</div>;
		}

		if (status === "error") {
			console.log(error);
			return (
				<div>Error loading Orders, please refresh the page and try again.</div>
			);
		}

		return <OrdersTable />;
	};

	return (
		<section className={wrapperStyle}>
			<h2 className={headerStyle}>Orders History</h2>
			<div className={contentWrapperStyle}>{renderContent()}</div>
		</section>
	);
};

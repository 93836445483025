import { InfoCircledIcon } from "@radix-ui/react-icons";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import {
	type FC,
	type PropsWithChildren,
	type ReactNode,
	useState,
} from "react";

import { colors, h100Style, t200Style } from "@/css";

import { tooltipContainerStyle, tooltipContentStyle } from "./Tooltip.css";

export type TooltipProps = {
	title?: string;
	description: ReactNode;
	tabIndex?: number;
	align?: "start" | "center" | "end";
} & Omit<TooltipPrimitive.TooltipContentProps, "open">;

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
	children,
	title,
	description,
	align = "start",
	tabIndex = 0,
	...props
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<TooltipPrimitive.Root {...props} open={isOpen}>
			<TooltipPrimitive.Trigger
				asChild
				onMouseEnter={() => {
					setIsOpen(true);
				}}
				onMouseLeave={() => {
					setIsOpen(false);
				}}
				onFocus={() => {
					setIsOpen(true);
				}}
				onBlur={() => {
					setIsOpen(false);
				}}
				tabIndex={tabIndex}
			>
				{children ?? <InfoCircledIcon color={colors.text.light} />}
			</TooltipPrimitive.Trigger>
			<TooltipPrimitive.Portal>
				<TooltipPrimitive.Content
					side="bottom"
					sideOffset={12}
					align={align}
					className={tooltipContentStyle}
				>
					<div className={tooltipContainerStyle}>
						{title ? <h5 className={h100Style}>{title}</h5> : null}
						<span className={t200Style}>{description}</span>
					</div>
				</TooltipPrimitive.Content>
			</TooltipPrimitive.Portal>
		</TooltipPrimitive.Root>
	);
};

import {
	CheckCircleIcon,
	ExclamationTriangleIcon,
	InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { type FC } from "react";
import { createPortal } from "react-dom";
import { Toaster as RawToaster } from "react-hot-toast";

import {
	errorToastStyle,
	infoToastStyle,
	successToastStyle,
} from "./Toaster.css";

export const Toaster: FC = () => {
	const toasterNode = (
		<RawToaster
			position="top-left"
			reverseOrder={false}
			gutter={8}
			toastOptions={{
				className: infoToastStyle,
				icon: <InformationCircleIcon />,
				success: {
					className: successToastStyle,
					icon: <CheckCircleIcon />,
				},
				error: {
					className: errorToastStyle,
					icon: <ExclamationTriangleIcon />,
				},
			}}
		/>
	);

	return createPortal(toasterNode, document.body);
};

import { FloodChain } from "flood-sdk/types";
import type { Address } from "viem";
import type { Plugin, Token } from "./types";

export const UNWRAP_HOOK_ADDRESS: Address =
	"0xEEF100d0e22090014dd68479c85035e28f9007e7";

export function createUnwrapHook(chain: FloodChain, wrapped: Token): Plugin {
	const unwrapHook: Plugin = {
		name: "Unwrap Hook",
		claims: ["postHooks", "recipient"],
		address: UNWRAP_HOOK_ADDRESS,
		chainId: chain.id,
		tokenList: {
			[chain.id]: [
				{
					...chain.nativeCurrency,
					chainId: chain.id,
					wrapped,
					pluginOut: UNWRAP_HOOK_ADDRESS,
				},
			],
		},
	};

	return unwrapHook;
}

export const plugins = [createUnwrapHook];

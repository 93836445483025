import { Howl } from "howler";
import { useAtom } from "jotai";
import { useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";

import arbitrageAudio from "@/assets/sounds/arbitrage.webm";
import notificationAudio from "@/assets/sounds/notification.webm";
import { isMutedAtom } from "@/services/userPreferences/userPreferences.store";

type NotifyOptions = {
	type?: "success" | "arbitrage" | "error" | "info";
};

const notificationHowl = new Howl({
	src: [notificationAudio],
	volume: 0.2,
});

const arbitrageHowl = new Howl({
	src: [arbitrageAudio],
	volume: 0.25,
});

export function useNotification() {
	const [isMuted] = useAtom(isMutedAtom);
	const isMutedRef = useRef(isMuted);

	useEffect(() => {
		isMutedRef.current = isMuted;
	}, [isMuted]);

	const notify = useCallback((message: string, opts?: NotifyOptions) => {
		const type = opts?.type ?? "info";

		if (!isMutedRef.current) {
			opts?.type === "arbitrage"
				? arbitrageHowl.play()
				: notificationHowl.play();
		}

		const toastOpts: Parameters<typeof toast> = [message];
		if (type === "success") {
			toast.success(...toastOpts);
		} else if (type === "error") {
			toast.error(...toastOpts);
		} else {
			toast(...toastOpts);
		}
	}, []);

	return notify;
}

import {
	MoonIcon,
	SpeakerWaveIcon,
	SpeakerXMarkIcon,
	SunIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { type ComponentPropsWithoutRef, type FC } from "react";
import { Link, useLocation } from "react-router-dom";

import FloodLogo from "@/assets/logos/flood.svg?react";
import { EthereumButton } from "@/components/EthereumButton";
import {
	useDarkMode,
	useMute,
} from "@/services/userPreferences/userPreferences.hooks";

import {
	activeLinkStyle,
	connectAndPreferencesContainerStyle,
	headerStyle,
	linkStyle,
	logoContainerStyle,
	logoStyle,
	navContainerStyle,
	preferencesContainerStyle,
	userPreferenceButtonStyle,
	userPreferenceIconStyle,
} from "./Header.css";

import { Tooltip } from "../Tooltip";

export const Header: FC<ComponentPropsWithoutRef<"header">> = ({
	children,
	...delegated
}) => {
	const { pathname } = useLocation();
	const { isDarkMode, toggleDarkMode } = useDarkMode();
	const { isMuted, toggleMute } = useMute();

	// const routes = [
	// 	{ to: "/", label: "Trade" },
	// 	{ to: "/history", label: "History" },
	// ];

	return (
		<header className={headerStyle} {...delegated}>
			<Link className={logoContainerStyle} to="/">
				<FloodLogo className={logoStyle} />
				<h1>Flood</h1>
			</Link>
			<nav className={navContainerStyle}>
				<Link
					key={"/history"}
					className={classNames({
						[linkStyle]: true,
						[activeLinkStyle]: "/history" === pathname,
					})}
					to={"/history"}
				>
					{"History"}
				</Link>
				{/* {routes.map(({ to, label }) => (
					<Link
						key={to}
						className={classNames({
							[linkStyle]: true,
							[activeLinkStyle]: to === pathname,
						})}
						to={to}
					>
						{label}
					</Link>
				))} */}
			</nav>
			<div className={connectAndPreferencesContainerStyle}>
				<EthereumButton fill={false} />
				<div className={preferencesContainerStyle}>
					<Tooltip
						description={isMuted ? "Unmute sounds" : "Mute sounds"}
						align="center"
					>
						<button
							onClick={toggleMute}
							className={userPreferenceButtonStyle}
							type="button"
						>
							{isMuted ? (
								<SpeakerWaveIcon className={userPreferenceIconStyle} />
							) : (
								<SpeakerXMarkIcon className={userPreferenceIconStyle} />
							)}
						</button>
					</Tooltip>
					<Tooltip
						description={isDarkMode ? "Enable light mode" : "Enable dark mode"}
						align="center"
					>
						<button
							onClick={toggleDarkMode}
							className={userPreferenceButtonStyle}
							type="button"
						>
							{isDarkMode ? (
								<SunIcon className={userPreferenceIconStyle} />
							) : (
								<MoonIcon className={userPreferenceIconStyle} />
							)}
						</button>
					</Tooltip>
				</div>
			</div>
		</header>
	);
};

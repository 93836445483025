import { bannerContainerStyle, bannerLinkStyle } from "./ArchiveBanner.css";

export const ArchiveBanner = () => {
	return (
		<div className={bannerContainerStyle}>
			FloodSwap v1 is not supported anymore. For more information, please follow
			our{" "}
			<a
				href="https://discord.gg/mrrW8yT5df"
				target="_blank"
				rel="noopener noreferrer"
				className={bannerLinkStyle}
			>
				Discord
			</a>
			.
		</div>
	);
};

import { SwapItem } from "@/services/swap/swap.store";
import { PricesMap } from "@/services/usdPrices";
import {
	type Dnum,
	add,
	divide,
	equal,
	format,
	from,
	greaterThan,
	lessThan,
	multiply,
	sub,
} from "dnum";
import { currencyId } from "./currency";

const THOUSANDS_SEPARATOR = " ";
export function formatCurrency(value?: Dnum, abbreviate = false) {
	if (!value) {
		return "-";
	}
	if (equal(value, from(0))) {
		return "0.00";
	}
	if (lessThan(value, from(0.00001)) && abbreviate) {
		return "<0.00001";
	}
	if (lessThan(value, from(10))) {
		return format(value, { locale: "en-US", digits: 5 }).replaceAll(
			",",
			THOUSANDS_SEPARATOR,
		);
	}
	if (lessThan(value, from(100))) {
		return format(value, { locale: "en-US", digits: 4 }).replaceAll(
			",",
			THOUSANDS_SEPARATOR,
		);
	}
	if (lessThan(value, from(1_000))) {
		return format(value, { locale: "en-US", digits: 3 }).replaceAll(
			",",
			THOUSANDS_SEPARATOR,
		);
	}
	return format(value, { locale: "en-US", digits: 2 }).replaceAll(
		",",
		THOUSANDS_SEPARATOR,
	);
}

export function formatPercentage(value: Dnum) {
	return `${format(multiply(value, 100), 2)}%`;
}

export function percentageOf(a: Dnum, b: Dnum): Dnum {
	if (equal(b, from(0))) {
		return from(0);
	}
	return divide(sub(a, b), b);
}

const ARBITRAGE_PCT_THRESHOLD = 0.03;
/**
 *
 * @param inputCurrencies The input currencies traded
 * @param outputCurrency The output currency traded
 * @param prices A mapping of currency to its USD price.
 * @returns boolean Wether or not the trade is immediately profitable in USD.
 */
export function detectArbitrage(
	inputCurrencies: SwapItem[],
	outputCurrency: SwapItem,
	prices: PricesMap,
): boolean {
	// Calculate the total input value in USD
	const inputValue = inputCurrencies.reduce(
		(acc, input) => {
			const price = prices[currencyId(input.currency)];
			if (!price) {
				throw new Error(`Missing price for ${currencyId(input.currency)}`);
			}
			// biome-ignore lint/style/noParameterAssign: This is a reducer
			acc = add(acc, multiply([input.amount, input.currency.decimals], price));
			return acc;
		},
		[0n, 18] as Dnum,
	);

	// Calculate the output value in USD
	const outputPrice = prices[currencyId(outputCurrency.currency)];
	if (!outputPrice) {
		throw new Error(`Missing price for ${currencyId(outputCurrency.currency)}`);
	}
	const outputValue = multiply(
		[outputCurrency.amount, outputCurrency.currency.decimals],
		outputPrice,
	);

	// Calculate the arbitrage percentage
	const pct = percentageOf(outputValue, inputValue);
	// Return true if the arbitrage percentage is above the threshold
	return greaterThan(pct, ARBITRAGE_PCT_THRESHOLD);
}

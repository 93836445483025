import { useAccount } from "@/hooks/account";
import { currencyId, isToken } from "@/utils/currency";
import { plugins } from "@/utils/plugins";
import type { Currency, Native, Token } from "@/utils/types";
import { useQuery } from "@tanstack/react-query";
import { FloodChain, arbitrum, getTokens } from "flood-sdk";
import { useMemo } from "react";
import { DEFAULT_CHAIN } from "./chain";

export function nativeWrappedAddress(chain: FloodChain): string {
	switch (chain.id) {
		case arbitrum.id:
			return "0x82af49447d8a07e3bd95bd0d56f35241523fbab1";
		default:
			throw new Error(`Unknown native wrapped version for ${chain.name}`);
	}
}

export type CurrencyMap = Record<string, Currency>;
export async function loadTokenList(
	chain: FloodChain,
): Promise<[Currency[], CurrencyMap]> {
	// Load the remote token list
	const { tokens } = await getTokens(chain);
	const wrappedNative: Currency | undefined = tokens.find(
		(t) => t.address === nativeWrappedAddress(chain),
	) as Token;
	if (!wrappedNative) {
		throw new Error("Wrapped Native currency not found.");
	}
	const [createUnwrapHook] = plugins;
	const unwrapHook = createUnwrapHook(chain, wrappedNative);
	const pluginEnabledTokens = unwrapHook.tokenList[chain.id];
	// Merge the two lists
	const mergedTokens = (tokens as Currency[])
		.concat(pluginEnabledTokens)
		.reduce(
			(acc: [Currency[], CurrencyMap], token: Currency) => {
				const key = currencyId(token);
				// If the array contains a token with the same id, merge them.
				const existingToken = acc[1][key];
				if (existingToken) {
					Object.assign(existingToken, token);
				} else {
					acc[1][key] = token;
					acc[0].push(token);
				}
				return acc;
			},
			[[], {}] as [Currency[], CurrencyMap],
		);

	return mergedTokens;
}

export function tokenListQueryKey(chainId: number) {
	return ["tokenList", chainId];
}

export function useTokenList() {
	const { chain: maybeChain } = useAccount();
	const chain = maybeChain ?? DEFAULT_CHAIN;
	const query = useQuery({
		queryKey: tokenListQueryKey(chain.id),
		queryFn: () => loadTokenList(chain),
		staleTime: Number.POSITIVE_INFINITY,
	});

	return { tokenList: query.data?.[0], tokenMap: query.data?.[1], ...query };
}

export function useNativeCurrency() {
	const { tokenList } = useTokenList();
	return useMemo(
		() => tokenList?.find((token) => !isToken(token)) as Native,
		[tokenList],
	);
}

import { useAccount } from "@/hooks/account";
import {
	fetchUserOrders,
	intoOriginalOrder,
} from "@/services/orders/orders.utils.ts";
import { arbitrum } from "@/utils/config";
import { isToken } from "@/utils/currency";
import { UNWRAP_HOOK_ADDRESS } from "@/utils/plugins";
import { type Currency } from "@/utils/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
	type FloodChain,
	type Order,
	type OrderWithStatus,
	type PaginationParams,
	PrimaryType,
	cancelOrder,
	floodPlainAbi,
	floodPlainAddress,
	intoPermit,
	permit2Domain,
	permit2NonceFinderAbi,
	permit2NonceFinderAddress,
	permit2WitnessTypes,
	submitOrder,
	watchOrders,
} from "flood-sdk";
import { useCallback, useEffect, useMemo } from "react";
import { type Address, maxUint256, zeroAddress } from "viem";
import { useReadContract, useSignTypedData } from "wagmi";
import { balancesQueryKey } from "../balances";
import { useAuthStore } from "../chain/auth.store";
import { type Intent, type IntentHook, IntentType } from "../intent";
import { useNotification } from "../notifications";
import { type SwapItem, type SwapStoreState } from "../swap/swap.store";
import { CurrencyMap, useTokenList } from "../tokenList";

export function useNextNonce(address?: Address) {
	return useReadContract({
		address: permit2NonceFinderAddress,
		abi: permit2NonceFinderAbi,
		functionName: "nextNonce",
		args: [address ?? zeroAddress],
		query: {
			enabled: Boolean(address),
		},
	});
}

function intoOrder(
	user: Address,
	inputCurrencies: SwapItem[],
	outputCurrency: Currency,
	minAmountOut: bigint,
	nonce: bigint,
): Order {
	const offerer = user;

	const offer = inputCurrencies.map(({ currency, amount }) => {
		const token = isToken(currency)
			? currency.address
			: currency.wrapped.address;
		return {
			token,
			amount,
		};
	});

	const consideration = isToken(outputCurrency)
		? {
				token: outputCurrency.address,
				amount: minAmountOut,
		  }
		: {
				token: outputCurrency.wrapped.address,
				amount: minAmountOut,
		  };

	// Add unwrap hook if output is not a token
	const postHooks = isToken(outputCurrency)
		? []
		: [
				{
					target: UNWRAP_HOOK_ADDRESS,
					data: user,
				},
		  ];

	const recipient = postHooks.length === 1 ? postHooks[0].target : offerer;

	return {
		offerer,
		offer,
		zone: arbitrum.contracts.defaultZone.address,
		consideration,
		preHooks: [],
		postHooks,
		deadline: maxUint256,
		nonce,
		recipient,
	};
}

export function useNextOrder(intent: Intent): IntentHook {
	const { address } = useAccount();
	const nonceQuery = useNextNonce(address);
	const nonce = nonceQuery.data;

	const order = useMemo(() => {
		if (
			!address ||
			nonce == null ||
			intent.type !== IntentType.NewOrder ||
			intent.estimatedOutputAmount == null
		) {
			return;
		}

		return intoOrder(
			address,
			intent.inputCurrencies,
			intent.outputCurrency,
			intent.estimatedOutputAmount,
			nonce,
		);
	}, [address, nonce, intent]);

	const { data: isValid, status: validStatus } = useReadContract({
		abi: floodPlainAbi,
		address: floodPlainAddress,
		functionName: "getOrderStatus",
		args: [order as Order],
		query: {
			enabled: Boolean(order),
		},
	});

	const sign = useSignTypedData();

	const action = useCallback(async () => {
		if (!order || validStatus !== "success" || !isValid) {
			return;
		}

		sign
			.signTypedDataAsync({
				domain: permit2Domain(arbitrum),
				types: permit2WitnessTypes,
				primaryType: PrimaryType.NEW,
				message: intoPermit(arbitrum, order),
			})
			.then((signature) => {
				submitOrder(arbitrum, { order, signature });
			});
	}, [order, isValid, validStatus, sign]);

	return { isValid: isValid ?? false, action, status: sign.status };
}

type FetchUserOrdersResponse = {
	orders: OrderWithMeta[];
	prevCursor?: string | undefined;
	nextCursor?: string | undefined;
};
export function useSubscribeToOrderEvents() {
	const notify = useNotification();
	const { address } = useAccount();
	const { tokenMap } = useTokenList();
	const authToken = useAuthStore((state) => state.token);
	const queryClient = useQueryClient();
	const { queryKey: nextNonceQueryKey } = useNextNonce(address);

	useEffect(() => {
		if (!address || !authToken || !tokenMap) return;
		const unsubscribe = watchOrders(arbitrum, authToken, {
			offerer: address,
			onFulfilled: (order) => {
				notify("Order fulfilled.", { type: "success" });
				queryClient.fetchQuery({
					queryKey: balancesQueryKey(address, arbitrum, "success"),
				});
				queryClient.invalidateQueries({ queryKey: nextNonceQueryKey });
				queryClient.setQueryData<FetchUserOrdersResponse | undefined>(
					userOrdersQueryKey(address, authToken, arbitrum, "success"),
					(data) => {
						return {
							orders: updateOrders(arbitrum, tokenMap, {
								oldOrders: data?.orders,
								updatedOrder: order,
							}),
							prevCursor: data?.prevCursor,
							nextCursor: data?.nextCursor,
						};
					},
				);
			},
			onCancelled: (order) => {
				notify("Order cancelled.", { type: "success" });
				queryClient.fetchQuery({
					queryKey: balancesQueryKey(address, arbitrum, "success"),
				});
				queryClient.invalidateQueries({ queryKey: nextNonceQueryKey });
				queryClient.setQueryData<FetchUserOrdersResponse | undefined>(
					userOrdersQueryKey(address, authToken, arbitrum, "success"),
					(data) => {
						return {
							orders: updateOrders(arbitrum, tokenMap, {
								oldOrders: data?.orders,
								updatedOrder: order,
							}),
							prevCursor: data?.prevCursor,
							nextCursor: data?.nextCursor,
						};
					},
				);
			},
			onNew: (order) => {
				// Trigger a refetch of the user's orders
				queryClient.invalidateQueries({ queryKey: nextNonceQueryKey });
				queryClient.setQueryData<Awaited<ReturnType<typeof fetchUserOrders>>>(
					userOrdersQueryKey(address, authToken, arbitrum, "success"),
					(data) => {
						return {
							orders: updateOrders(arbitrum, tokenMap, {
								oldOrders: data?.orders,
								updatedOrder: order,
							}),
							prevCursor: data?.prevCursor,
							nextCursor: data?.nextCursor,
						};
					},
				);
			},
		});

		return () => {
			unsubscribe.then((un) => un());
		};
	}, [address, authToken, queryClient, notify, nextNonceQueryKey, tokenMap]);
}

export function userOrdersQueryKey(
	address: Address | undefined,
	authToken: string | null,
	chain: FloodChain | undefined,
	status: "idle" | "pending" | "success" | "error",
	pagination?: PaginationParams,
) {
	const limit = pagination?.limit || "";
	const beforeCursor =
		(pagination && "beforeCursor" in pagination && pagination?.beforeCursor) ||
		"";
	const afterCursor =
		(pagination && "afterCursor" in pagination && pagination?.afterCursor) ||
		"";
	return [
		"user-orders",
		authToken,
		address,
		chain?.id,
		`tokenList-${status}`,
		limit,
		beforeCursor,
		afterCursor,
	];
}

export type OrderWithMeta = OrderWithStatus & {
	intent: Pick<SwapStoreState, "inputCurrencies"> & {
		outputCurrency: SwapItem;
	};
};

function updateOrders(
	chain: FloodChain,
	tokenMap: CurrencyMap,
	{
		oldOrders,
		updatedOrder,
	}: { oldOrders: OrderWithMeta[] | undefined; updatedOrder: OrderWithStatus },
) {
	const updatedOrderWithMeta = intoOriginalOrder(chain, updatedOrder, tokenMap);
	if (!oldOrders) return [updatedOrderWithMeta];
	const index = oldOrders.findIndex((o) => o.hash === updatedOrder.hash);
	if (index === -1) return [updatedOrderWithMeta, ...oldOrders];

	oldOrders[index] = updatedOrderWithMeta;
	return oldOrders;
}

export function useUserOrders(pagination?: PaginationParams) {
	const { address, chain } = useAccount();
	const authToken = useAuthStore((state) => state.token);
	const { tokenMap, status } = useTokenList();

	const ordersQuery = useQuery({
		queryKey: userOrdersQueryKey(address, authToken, chain, status),
		queryFn: async () => {
			return fetchUserOrders(address, authToken, tokenMap, chain, pagination);
		},
		enabled:
			Boolean(address) &&
			Boolean(authToken) &&
			status === "success" &&
			Boolean(tokenMap) &&
			Boolean(chain),
		initialData: {
			orders: [],
		},
	});

	return { orders: ordersQuery.data?.orders || [], ...ordersQuery };
}

export function useCancelOrder(order: Order) {
	const sign = useSignTypedData();

	const action = useCallback(() => {
		sign
			.signTypedDataAsync({
				domain: permit2Domain(arbitrum),
				types: permit2WitnessTypes,
				primaryType: PrimaryType.CANCEL,
				message: order,
			})
			.then((signature) => {
				cancelOrder(arbitrum, { order, signature });
			});
	}, [order, sign]);

	return { action, status: sign.status, error: sign.error };
}
